/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with jQuery, even when in .noConflict() mode.
 * ======================================================================== */

(function(jQuery) {


  // asd
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        jQuery('.increase, .decrease').on('click touchstart', function() {
          jQuery('[name="update_cart"]').prop('disabled', false);
        });

        jQuery('.add_to_cart_button').on('click', function() {
          var addedToCart = jQuery('.added_to_cart');
          addedToCart.removeClass('added_to_cart');
          setTimeout(function() {
            addedToCart.addClass('added_to_cart');
          }, 10); // 10 milliseconds delay is usually enough
        });

        jQuery('img[class*=" wp-image-"]').each(function() {
          var src = jQuery(this).attr('src');
          jQuery(this).wrap('<a href="' + src + '" data-fancybox/>');
        });

        function peopleAutomatic() {
          var nextToFirst = false;
          var automatic = setInterval(function () {

            jQuery('.people-tabs .active').parent().next('li').find('a').trigger('click');

            var all = jQuery('.people-tabs').find('li');
            var next = jQuery('.people-tabs .active').parent().next('li');

            if (next.length === 0) {
              if (nextToFirst) {
                jQuery(all[0]).find('a').trigger('click');
              }
              nextToFirst = true;
            } else {
              nextToFirst = false;
            }

          }, 3000);

          jQuery('.people-module').on('mouseover', function (){
            clearInterval(automatic);
          });

          jQuery('.people-module').on('mouseleave', function (){
            clearInterval(automatic);
            var nextToFirst = false;
            automatic = setInterval(function () {

              jQuery('.people-tabs .active').parent().next('li').find('a').trigger('click');

              var all = jQuery('.people-tabs').find('li');
              var next = jQuery('.people-tabs .active').parent().next('li');

              if (next.length === 0) {
                if (nextToFirst) {
                  jQuery(all[0]).find('a').trigger('click');
                }
                nextToFirst = true;
              } else {
                nextToFirst = false;
              }

            }, 3000);
          });

        }
        peopleAutomatic();

        // function hoverMenu() {
        //   jQuery('.menu-item-has-children').mouseenter(function () {
        //     jQuery(this).children('.sub-menu').addClass('opened');
        //   });
        //   jQuery('.menu-item-has-children').mouseleave(function () {
        //     jQuery(this).children('.sub-menu').removeClass('opened');
        //   });
        // }
        // hoverMenu();
        var numbersAnimated = false;

        var numbersModule = jQuery('.numbers-module');
        function numberAnimation() {
          jQuery('.numbers-module .number').each(function () {
            var number = jQuery(this);
            jQuery({ Counter: 0 }).animate({ Counter: jQuery(number).text().replace(/[^0-9.]/g, "") }, {
              duration: 1500,
              easing: 'swing',
              step: function () {
                jQuery(number).text(Math.ceil(this.Counter));
              }
            });
          });

          numbersAnimated = true;
        }

        function menuPosition() {
          if (jQuery(document).scrollTop() > 10){
            jQuery("header").addClass("not-top");
          } else {
            jQuery("header").removeClass("not-top");
          }
        }

        menuPosition();

        jQuery(document).on("scroll", function(){
          menuPosition();

          if (jQuery(numbersModule).isInViewport && !numbersAnimated) {
            numberAnimation();
          } else if (!jQuery(numbersModule).isInViewport && numbersAnimated) {
            numbersAnimated = false;
          }
        });

        var menuItem = jQuery('.expander');
        if(jQuery(window).width() <= 1080) {
          menuItem.click(function(e) {
            if(jQuery(e.target).closest('.menu-item.menu-item-has-children').hasClass('open')) {
              jQuery(e.target).closest('.menu-item.menu-item-has-children').removeClass('open');
            } else {
              jQuery(e.target).closest('.menu-item.menu-item-has-children').addClass('open');
            }
          });
        }

        var searchBtn = jQuery('.search-button');
        var searchForm = jQuery('.search-form')[0];
        var closeSearch = jQuery('.close-button');

        searchBtn.on('click', function() {
          searchBtn.hide();
          closeSearch.show().css('display', 'block');
          jQuery(searchForm).addClass('active');

          setTimeout(function() {
            jQuery(searchForm).find('input').focus();
          }, 200);

          return false;
        });

        jQuery(searchForm).on('keyup', function(e) {
          if(e.key === 'Enter') {
            jQuery(searchForm).submit();
          }
        });

        jQuery(document).keyup(function(e) {
            if (e.key === "Escape") {
              if(jQuery(searchForm).hasClass('active')) {
                jQuery(searchForm).removeClass('active');
              }
              closeSearch.hide();
              searchBtn.show().css('display', 'block');
            }
        });

        jQuery(closeSearch).on('click', function(e) {
          closeSearch.hide();
          searchBtn.show().css('display', 'block');
          e.preventDefault();
          e.stopPropagination();
        });

        jQuery(document).on('click', function(e) {
          if (!jQuery(e.target).is('input.search-field') && jQuery(searchForm).hasClass('active') && jQuery(e.target) !== jQuery(searchBtn)) {
            jQuery(searchForm).removeClass('active');
            closeSearch.hide();
            searchBtn.show().css('display', 'block');
          }
       });



        jQuery('.jobs-next').click(function() {
          jQuery('.jobs-tabs .active').parent().next('li').find('a').trigger('click');
        });

        jQuery('.jobs-previous').click(function() {
          jQuery('.jobs-tabs .active').parent().prev('li').find('a').trigger('click');
        });

        var nextToFirst = false;
        var prevToFirst = true;

        jQuery('.people-next').click(function() {
          jQuery('.people-tabs .active').parent().next('li').find('a').trigger('click');
          prevToFirst = false;

          var all = jQuery('.people-tabs').find('li');
          var next = jQuery('.people-tabs .active').parent().next('li');

          if (next.length === 0) {
            if (nextToFirst) {
              jQuery(all[0]).find('a').trigger('click');
              prevToFirst = true;
            }
            nextToFirst = true;
          } else {
            nextToFirst = false;
          }

        });

        jQuery('.people-previous').click(function() {
          jQuery('.people-tabs .active').parent().prev('li').find('a').trigger('click');
          nextToFirst = false;

          var all = jQuery('.people-tabs').find('li');
          var prev = jQuery('.people-tabs .active').parent().prev('li');

          if (prev.length === 0) {
            if (prevToFirst) {
              jQuery(all[all.length-1]).find('a').trigger('click');
              nextToFirst = true;
            }
            prevToFirst = true;
          } else {
            prevToFirst = false;
          }

        });

        jQuery(document).on('shown.bs.tab', '#meie-inimesed .nav-tabs li a[data-toggle="tab"]', function (e) {
          var element = jQuery('#people_list_content .active .row');
          jQuery('#people-module').css("background-image", element.css('backgroundImage'));
        });

        jQuery('.addresume').on('click', function() {
          jQuery(this).closest('form').find('.resume-input').find('.resumeclick').click();
        });

        jQuery('.resume-input').on('change', function() {
          var filename = jQuery(this).find('input[type=file]').val().split('\\').pop();
          jQuery(this).closest('form').find('.resume-name').html(filename);
        });

        jQuery(document).ready(function() {
          var current = window.location.hash;
          var splitted = current.split('-');

          if (splitted[1]) {
            var real_link = '#collapse-' + splitted[1];
            jQuery(real_link).collapse();
            jQuery('html, body').animate({
              scrollTop: jQuery(real_link).offset().top - 200
            }, 500);
          }
        });


        var hasLoaded = false;

        function addThisLinksInteractive() {
          var link = jQuery('.at-svc-link');
          link.attr('data-toggle', 'tooltip');
          link.attr('data-placement', 'left');
          link.attr('title', 'Kopeeri link');

          if (link.length !== 0) {
            hasLoaded = true;
          }

          var messenger = jQuery('.at-svc-messenger');
          messenger.attr('data-toggle', 'tooltip');
          messenger.attr('data-placement', 'left');
          messenger.attr('title', 'Jaga messengeris');

          var mailto = jQuery('.at-svc-mailto');
          mailto.attr('data-toggle', 'tooltip');
          mailto.attr('data-placement', 'left');
          mailto.attr('title', 'Jaga e-postiga');

          var facebook = jQuery('.at-svc-facebook');
          facebook.attr('data-toggle', 'tooltip');
          facebook.attr('data-placement', 'left');
          facebook.attr('title', 'Jaga facebookis');

          var whatsapp = jQuery('.at-svc-whatsapp');
          whatsapp.attr('data-toggle', 'tooltip');
          whatsapp.attr('data-placement', 'left');
          whatsapp.attr('title', 'Jaga whatsappis');


          jQuery('[data-toggle="tooltip"]').tooltip();
        }


        var addThisInterval = setInterval(function () {
          if (!hasLoaded) {
            addThisLinksInteractive();
          } else {
            clearInterval(addThisInterval);
          }
        }, 1000);


        jQuery('.sticky-buttons').html(jQuery('#hero-module .read-more-container').html());

        jQuery('.map-item').on('click mouseover', function() {
          jQuery('.map-item.visible').removeClass('visible');
          jQuery('.map-item-content.visible').removeClass('visible');
          jQuery(this).find('.map-item-content').addClass('visible');
          jQuery(this).addClass('visible');
        });

        jQuery('body').on('click', function(e) {
          if( (!jQuery(e.target).hasClass('map-item') && !jQuery(e.target).hasClass('map-item-content') && jQuery(e.target).closest('.map-item-content').length === 0) || jQuery(e.target).hasClass('close')) {
            jQuery('.map-item.visible').removeClass('visible');
            jQuery('.map-item-content.visible').removeClass('visible');
          }
        });

        jQuery( ".map-item" ).each(function( index ) {
          var pos = jQuery(this)[0].style.left;
          var pos_int = parseInt(pos.replace('%', ''));
          if (jQuery(this).hasClass('mobile') && pos_int > 40) {
            jQuery(this).addClass('content-left');
          } else if (pos_int > 60) {
            jQuery(this).addClass('content-left');
          }
        });

        if ( window.innerWidth <= 1080) {
          jQuery('.values-list .collapse').each(function (index) {
            jQuery(this).collapse('hide');
          });
        }

        jQuery( ".current-job" ).each(function( index ) {
          var title = jQuery(this).closest('.job-candidate').find('.get-job-title').val();
          jQuery(this).val(title);
        });

        jQuery( ".current-intern" ).each(function( index ) {
          var title = jQuery(this).closest('.intern-candidate').find('.get-job-title').val();
          jQuery(this).val(title);
        });

        jQuery('#navbarSupportedContent').on('hidden.bs.collapse', function () {
          jQuery('body').removeClass('menu-open');
        });

        jQuery('#navbarSupportedContent').on('shown.bs.collapse', function () {
          jQuery('body').addClass('menu-open');
        });
        // JavaScript to be fired on all pages
        jQuery.fn.isInViewport = function() {
          var elementTop = jQuery(this).offset().top;
          var elementBottom = elementTop + jQuery(this).outerHeight();

          var viewportTop = jQuery(window).scrollTop();
          var viewportBottom = viewportTop + jQuery(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        var players = [];

        jQuery('.video').each(function() {
          players.push({
            player: new Vimeo.Player(jQuery(this).find("iframe").get(0)),
            top: jQuery(this).offset().top,
            status: "paused"
          });
        });

        jQuery('.play-button').click(function(e) {
          var currVideo = jQuery(e.target).parent().find('iframe');
          for(var i=0; i<players.length;i++) {
            var status;
            if(currVideo) {
              status = 'play';
              jQuery(e.target).hide();
            }

            if(players[i].status !== status) {
              players[i].status = status;
              players[i].player[status]();
            }
          }
        });

        // jQuery(window).on('scroll', function() {
        //   for(var i=0; i<players.length;i++) {
        //     var status;
        //     if(jQuery(players[i].player.element).isInViewport()) {
        //       status = 'play';
        //     } else {
        //       status = 'pause';
        //     }
        //     if(players[i].status !== status) {
        //       players[i].status = status;
        //       players[i].player[status]();
        //     }
        //   }
        // });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var benefitTab = jQuery('.benefits-tabs .nav-link');
        jQuery(benefitTab).click(function (e) {
          e.preventDefault();
          var target = jQuery(e.target).closest('.nav-link');
          var image = jQuery(target).data('image');
          if(image) {
            jQuery('.benefits-header').attr('style', "background-image: url('" +  image  + "')");
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        function benefitsVideoPos () {
          var navTabs = jQuery('.nav-tabs-wrapper');
          var iFrame = jQuery('.benefits-module iframe');
          var iFrameWrapper = jQuery('.benefits-module .video-wrapper');
          var navTabsHeight = jQuery(navTabs.outerHeight());
          iFrameWrapper.css({paddingBottom: navTabsHeight[0]});
          jQuery(iFrame).css( {  height: 'calc(100% - ' + navTabsHeight[0] + 'px)' } );
        }

        jQuery(window).on('resize', function() {
          benefitsVideoPos();
        });

        setTimeout(function() {
          benefitsVideoPos();
        }, 1500);

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      jQuery.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
